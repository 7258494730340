import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';
import Disclaimer from '../components/Disclaimer';

const WhatsAppPolacy = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Ocal naszą przyszłość - głosuj w wyborach do EU 🗳"
      description="Kliknij w link i wyślij wiadomość to znajomych na WhatsAppie - daj im głos! ➡️"
      shareImage="polacy"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hej! Jesteś Polką/Polakiem i mieszkasz w UK?
Masz prawo głosować w europejskich wyborach parlamentarnych!

*WAŻNE!!!*
 Prześlij tę wiadomość do swoich polskich przyjaciół w UK - to jest równiez NASZ dom! 💥

Kliknij tutaj: https://saveourfuture.uk/polacy

Zarejestruj się, by głosować:  *Do 7 maja kliknij tutaj - to proste! (zajmuje 2 minuty)*
https://saveourfuture.uk/simple

*WAŻNE!!!* 💥 Prześlij tę wiadomość do swoich polskich przyjaciół w UK - to jest równiez NASZ dom💥 Kliknij tutaj: https://saveourfuture.uk/polacy

🗣 *TYM RAZEM NASZ GŁOS SIĘ LICZY!!!* 🗣`}
    />
    <Interstitial>
      <p>Wybierz pięciu najlepszych przyjaciół lub grup, aby wysłać wiadomość Whatsapp</p>
      <p>Generowanie wiadomości teraz</p>
      <p>Możesz spersonalizować wiadomość w następnym kroku…</p>
    </Interstitial>
    <Disclaimer />
  </Layout>
);

WhatsAppPolacy.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppPolacy;

export const query = graphql`
  query PolacyQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
